
const audioLists = [
    {
      name: "Mansa",
      singer: "Jador",
      cover:
        "https://res.cloudinary.com/bliss-records/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1633996313/mansa2_puyhz7.jpg",
      musicSrc:
        "https://res.cloudinary.com/bliss-records/video/upload/v1633996508/yt5s.com_-_J_ador_-_Mansa_128_kbps_h5s30n.mp3"
    },
   
  ];
  export default audioLists