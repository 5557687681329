import styled from "styled-components";

export const Home = styled.div`

`
export const HomeBackground = styled.video`
object-fit: cover;
height: 80vh;
top: 0;
margin-top:-90px;
width:'200vw';
`
export const HomeBg = styled.h1`
color:white;
font-family: 'Source Code Pro', monospace;
justify-content:center;
text-align:center;
margin:10rem;
position: relative;
font-weight:bold

`